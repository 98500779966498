import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'

const Container = styled.div`
  max-width: 767px;
  margin: 0 auto;
  padding: 3em 1em;
`
const Heading = styled.h1`
  font-size: calc(1.35vw + 1.35em);
  margin-bottom: 0.25em;
`
const Subheading = styled.h2`
  font-size: calc(0.75vw + 1em);
`
const Content = styled.p``

const PrivacyPolicy = () => (
  <StaticQuery
    query={graphql`
      query WebsiteInfoForPrivacy {
        site {
          siteMetadata {
            title
            siteUrl
            description
            companyInfo {
              phone
              email
              addressLine1
              addressLine2
              city
              state
              zip
              license
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Layout>
          <SEO title="Privacy" />
          <Container>
            <Heading>Our Commitment To Privacy</Heading>
            <Content>Effective Date: April 17, 2019</Content>
            <Content>
              Your privacy is important to us so we’ve developed a Privacy
              Policy that covers how we collect, use, and store your personal
              information.
            </Content>
            <hr />
            <p>
              This privacy notice discloses the privacy practices for (
              {data.site.siteMetadata.siteUrl}). This privacy notice applies
              solely to information collected by this website, except where
              stated otherwise. It will notify you of the following:
            </p>
            <ul>
              <li>What information we collect;</li>
              <li>With whom it is shared;</li>
              <li>How it can be corrected;</li>
              <li>How it is secured;</li>
              <li>How policy changes will be communicated; and</li>
              <li>How to address concerns over misuse of personal data.</li>
            </ul>
            <Subheading>Information Collection, Use, and Sharing</Subheading>
            <p>
              We are the sole owners of the information collected on this site.
              We only have access to/collect information that you voluntarily
              give us via email or other direct contact from you. We will not
              sell or rent this information to anyone.
            </p>
            <p>
              We will use your information to respond to you, regarding the
              reason you contacted us. We will not share your information with
              any third party outside of our organization, other than as
              necessary to fulfill your request, e.g., to ship an order.
            </p>
            <p>
              Unless you ask us not to, we may contact you via email in the
              future to tell you about specials, new products or services, or
              changes to this privacy policy.
            </p>
            <Subheading>Your Access to and Control Over Information</Subheading>
            <p>
              You may opt out of any future contacts from us at any time. You
              can do the following at any time by contacting us via the email
              address or phone number provided on our website:
            </p>
            <ul>
              <li>
                <p>See what data we have about you, if any.</p>
              </li>
              <li>
                <p>Change/correct any data we have about you.</p>
              </li>
              <li>
                <p>Have us delete any data we have about you.</p>
              </li>
              <li>
                <p>Express any concern you have about our use of your data.</p>
              </li>
            </ul>
            <Subheading>Cookies</Subheading>
            <p>
              We use "cookies" on this site. A cookie is a piece of data stored
              on a site visitor's hard drive to help us improve your access to
              our site and identify repeat visitors to our site. For instance,
              when we use a cookie to identify you, you would not have to log in
              a password more than once, thereby saving time while on our site.
              Cookies can also enable us to track and target the interests of
              our users to enhance their experience on our site. Usage of a
              cookie is in no way linked to any personally identifiable
              information on our site.
            </p>
            <Subheading>Security</Subheading>
            <p>
              We take precautions to protect your information. When you submit
              sensitive information via the website, your information is
              protected both online and offline.
            </p>
            <p>
              Wherever we collect sensitive information (such as credit card
              data), that information is encrypted and transmitted to us in a
              secure way. You can verify this by looking for a closed lock icon
              at the bottom of your web browser, or looking for "https" at the
              beginning of the address of the web page.
            </p>
            <p>
              While we use encryption to protect sensitive information
              transmitted online, we also protect your information offline. Only
              employees who need the information to perform a specific job (e.g,
              billing or customer service) are granted access to personally
              identifiable information. The computers/servers on which we store
              personally identifiable information are kept in a secure
              environment.
            </p>

            <Subheading>Notification of Changes</Subheading>
            <p>
              We may use your personal information to send important notices,
              such as communications about changes to our terms, conditions, and
              policies. Because this information is important to your
              interaction with {data.site.siteMetadata.title}, you may not opt
              out of receiving these communications.
            </p>
            <p>
              <strong>
                If you feel that we are not abiding by this privacy policy, you
                should contact us immediately via telephone at{' '}
                {data.site.siteMetadata.companyInfo.phone} or via email.{' '}
              </strong>
            </p>
          </Container>
        </Layout>
      </>
    )}
  />
)

export default PrivacyPolicy
